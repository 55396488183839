import { Fragment } from 'react'
// eslint-disable-next-line import/no-internal-modules
import css from 'styled-jsx/css'

import { FooterProps } from './footer'
import { PrimaryNavEntry } from './primary-nav'
import { TransLink } from '@/components/trans-link'

interface SecondaryNavProps {
  data: FooterProps['data']['secondaryMenu']
}

export function SecondaryNav({ data }: SecondaryNavProps) {
  return (
    <nav className="secondary">
      {data.map(renderLink)}

      <style jsx>{`
        .secondary {
          text-align: right;
        }
      `}</style>
    </nav>
  )

  function renderLink({ title, href }: PrimaryNavEntry) {
    return (
      <Fragment key={href}>
        <TransLink href={href} className={className + ' '}>
          {title}
        </TransLink>
        {styles}
      </Fragment>
    )
  }
}

const { className, styles } = css.resolve`
  a {
    font-size: 0.65rem !important;
    margin-left: 0.4rem;
    border-bottom-width: 0 !important;
    &:hover {
      border-bottom: 1px solid black !important;
    }
  }
`
