import { useEffect, useState } from 'react'

export function YouCanScrollYouKnow() {
  const [show, setShow] = useState(false)

  function isScrolled() {
    return window.scrollY > 100
  }
  function isReallyScrolled() {
    return window.scrollY > 500
  }

  useEffect(() => {
    // not scrolled after some seconds: show
    setTimeout(() => {
      if (!isScrolled()) {
        setShow(true)
      }
    }, 3000)
  })

  useEffect(() => {
    if (!show) return
    // hide if scrolled
    window.addEventListener('scroll', handleScroll)
    return function cleanupListener() {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  function handleScroll() {
    if (isReallyScrolled()) {
      setShow(false)
      window.removeEventListener('scroll', handleScroll)
    }
  }

  function clickHandler() {
    setShow(false)

    const title = document.getElementById('page-title')
    const afterTitle = document.getElementById('after-title')

    //second child of content container. will probably break in the future ^^
    const secondChildHack =
      afterTitle?.firstElementChild?.firstElementChild?.nextElementSibling

    const scrollTarget = title ? afterTitle : secondChildHack

    if (scrollTarget) scrollTarget?.scrollIntoView({ behavior: 'smooth' })
    else window.scroll({ top: 700, behavior: 'smooth' })
  }

  return (
    <a
      className={'you-can-scroll-you-know ' + (show ? 'show' : 'hide')}
      title="Zum Inhalt"
      onClick={clickHandler}
    >
      ☟
      <style jsx>{`
        a.you-can-scroll-you-know {
          position: fixed;
          right: 2rem;
          top: 3.7rem;
          background-color: var(--brand-light);
          width: 5rem;
          height: 5rem;
          color: #fff;
          font-size: 3.5rem;
          text-align: center;
          border-bottom: 0;
          cursor: pointer;
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.7s ease-in !important;

          &.show {
            opacity: 1;
            pointer-events: all;
            transition: opacity 4s !important;
          }
        }
      `}</style>
    </a>
  )
}
