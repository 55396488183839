import { useEffect } from 'react'

import { TransLink } from '@/components/trans-link'

export function Header({ small }: { small?: boolean }) {
  useEffect(() => {
    const hash = window.location.hash
    window.location.hash = ''
    window.location.hash = hash
  })

  return (
    <>
      <header className="logo">
        <TransLink href="/" className="no-under">
          <img
            src={`/_assets/wittendoerfer-logo${small ? '-sm' : ''}.svg`}
            alt="Wittendoerfer Logo"
            width={small ? '110' : '368'}
            height="36"
          />
        </TransLink>
        <style jsx>{`
          .logo {
            max-width: 20rem;
            margin-bottom: 1.5rem;
            padding-bottom: 0;

            img {
              max-width: 100%;
              height: auto;
            }
          }
        `}</style>
        <style jsx>{`
          .logo {
            margin-top: ${small ? '1rem' : '4rem'};
          }
        `}</style>
      </header>
    </>
  )
}
