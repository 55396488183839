import { GetStaticProps, InferGetStaticPropsType } from 'next'
// eslint-disable-next-line import/no-internal-modules
import { serialize } from 'next-mdx-remote/serialize'

import { AuthorData } from '@/components/author-card'
import {
  Blocks,
  ImageColumnBlock,
  PageBlocks,
} from '@/components/blocks/page-blocks'
import { BookData } from '@/components/book-card'
import { Footer, FooterProps } from '@/components/footer/footer'
import { Header } from '@/components/header/header'
import { YouCanScrollYouKnow } from '@/components/header/you-can-scroll-you-know'
import { getAllSlugs } from '@/helper/get-all-slugs'
import { getAuthors } from '@/helper/get-authors'
import { getBooks } from '@/helper/get-books'
import { getDataBySlug } from '@/helper/get-data-by-slug'
import { getImageSize } from '@/helper/get-image-size'
import { getMenuData } from '@/helper/get-menu-data'
import { hasOwnPropertyTs } from '@/helper/has-own-property-ts'

export interface SimplePageProps {
  data: {
    title: string
    slug: string
    images: ImageColumnBlock[]
    blocks: Blocks
    config: {
      hideTitle?: boolean
      forceColumns?: boolean
    }
  }
  menuData: FooterProps['data']
  books: BookData[]
  authors: AuthorData[]
}

export default function PageSlug(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  if (!props || props == {}) return null
  const { data, menuData, books, authors } = props as SimplePageProps

  const hasColums = data.config?.forceColumns ? true : data.images?.length

  return (
    <div id={`page-${data.slug}`}>
      <Header />
      {data.slug === 'index' ? <YouCanScrollYouKnow /> : null}
      {renderTitle()}
      <main>
        <div
          id="after-title"
          className={`after-title serif ${hasColums ? 'columns' : ''}`}
        >
          <div className="content">
            <PageBlocks blocks={data.blocks} books={books} authors={authors} />
          </div>
          <div className="image-space">
            <PageBlocks blocks={data.images} />
          </div>
        </div>
      </main>
      <style jsx>{`
        h2 {
          margin-top: 54vh;
        }
        .after-title {
          margin-top: 8rem;

          &.columns {
            @media (min-width: 780px) {
              display: flex;
              justify-content: space-between;
              .content {
                width: 47%;
                max-width: 47%;
              }
              .spacer,
              .image-space {
                width: 47%;
                max-width: 47%;
              }
            }
          }
        }
      `}</style>
      <Footer data={menuData} />
    </div>
  )

  function renderTitle() {
    if (data.config?.hideTitle) return null
    return (
      <h1 id="page-title" style={{ marginTop: '45vh', paddingBottom: '1rem' }}>
        {data.title}
      </h1>
    )
  }
}

export const getStaticProps: GetStaticProps<SimplePageProps | {}> = async ({
  params,
}) => {
  const slug = params?.slug?.toString()

  const data = getDataBySlug('pages', slug).data as SimplePageProps['data']

  const blocks = data.blocks
    ? await Promise.all(
        data.blocks?.map(async (block) => {
          const mdxBlock = hasOwnPropertyTs(block, 'body')
            ? await serialize((block.body as string) ?? '')
            : null
          const sizes = block.type === 'BlueImage' ? getImageSize(block) : null
          return { ...block, body: mdxBlock, ...sizes }
        }) ?? null
      )
    : null

  const images = data.images
    ? data.images.map((block) => {
        const sizes = block.type === 'BlueImage' ? getImageSize(block) : null
        return { ...block, ...sizes }
      })
    : null

  const menuData = getMenuData()
  const authors = await Promise.all(getAuthors())
  const books = await Promise.all(getBooks())

  return {
    props: {
      data: { ...data, blocks, images },
      menuData,
      books,
      authors,
    },
  }
}

export async function getStaticPaths() {
  const slugs = getAllSlugs('pages')
  const paths = slugs.map((slug) => {
    return {
      params: {
        slug: [slug],
      },
    }
  })
  return {
    paths,
    fallback: false,
  }
}
