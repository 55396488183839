import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { GradientBlock } from '../blocks/gradient-block'
import { PrimaryNav, PrimaryNavEntry } from './primary-nav'
import { SecondaryNav } from './secondary-nav'
import { TransLink } from '@/components/trans-link'

export interface FooterProps {
  data: {
    primaryMenu: PrimaryNavEntry[]
    secondaryMenu: PrimaryNavEntry[]
  }
}

export function Footer({ data }: FooterProps) {
  const [showFullMail, setShowFullMail] = useState(false)
  const router = useRouter()

  const route = router.asPath.split('#')[0]
  const showContact = route === '/' || route === '/verlag'

  useEffect(() => {
    if (!showContact) return
    setShowFullMail(true)
  }, [showContact])

  return (
    <footer id="contact">
      {showContact ? (
        <div className="serif">
          Wittendoerfer Verlagsgesellschaft
          <br />
          Hohenzollernstraße 122
          <br />
          80796 München
          <br />
          {showFullMail ? (
            <a href="mailto:as@wittendoerfer.de">as@wittendoerfer.de</a>
          ) : (
            <p>as &amp;at&amp; wittendoerfer &amp;dot&amp; de</p>
          )}
          <style jsx>{`
            div {
              margin-top: 9rem;
            }
            @media (min-width: 680px) {
              div {
                /* display: flex; */

                justify-content: space-between;
              }
            }
          `}</style>
          <GradientBlock height="30" />
        </div>
      ) : null}

      <div>
        <TransLink href="/" className="no-under">
          <img
            src="/_assets/wittendoerfer-logo-sm.svg"
            alt="Wittendoerfer Logo small"
            width="96"
            height="8.5"
          />
        </TransLink>
        <PrimaryNav data={data.primaryMenu} />
        <style jsx>{`
          img {
            width: 8rem;
            height: 3rem;
          }
          div {
            margin-top: 5rem;
            text-align: right;
            @media (min-width: 780px) {
              display: flex;
              justify-content: space-between;
            }
          }
        `}</style>
      </div>
      <SecondaryNav data={data.secondaryMenu} />
    </footer>
  )
}
